import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { DropdownWrapper } from "@circle-react-uikit/HeaderV3/DropdownWrapper";
import { IconButton } from "@circle-react-uikit/HeaderV3/IconButton";
import { useKeyboardShortcutsModal } from "@circle-react-uikit/HeaderV3/KeyboardShortcutsModal";

export const LeftActionBlock = () => {
  const keyboardShortcutsModal = useKeyboardShortcutsModal();

  return (
    <DropdownWrapper>
      <TippyV2 interactive={false} content={t("header_v3.keyboard_shortcuts")}>
        <IconButton
          name="16-keyboard-shortcuts"
          buttonSize={28}
          iconClassName="!text-c-header"
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover focus:!bg-c-header-hover rounded-md"
          onClick={keyboardShortcutsModal.show}
          ariaLabel={t("header_v3.keyboard_shortcuts")}
          dataTestId="keyboard-shortcuts"
        />
      </TippyV2>
    </DropdownWrapper>
  );
};
