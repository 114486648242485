import { t } from "@/i18n-js/instance";
import { BotChatList } from "@circle-react/components/Modals/MessagingModal/LeftSidebar/List/BotChatList";
import { useShouldShowAgentsTab } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/useShouldShowAgentsTab";
import { CounterBadge } from "@circle-react/components/shared/CounterBadge";
import { roundedCount } from "@circle-react/helpers/notificationHelpers";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useChatNotification } from "@circle-react/hooks/chatsV2/useChatNotification";
import { useShouldShowMessagingRoutes } from "@circle-react/hooks/useIsMessagingEnabled";
import { Icon } from "@circle-react-shared/Icon";
import { Tab } from "@circle-react-uikit/TabV2";
import { DMsList } from "./List";
import { ListWrapper } from "./List/ListWrapper";
import { NoUnreadMessages } from "./NoUnreadMessages";

export const TabHeader = ({ className = "" }) => {
  const { unreadChatRoomUuids = [] } = useChatNotification();
  const unreadChatRoomsCount = unreadChatRoomUuids.length;
  const { totalChatsCount: totalBotChatCount } = useCommunityInboxStore();
  const { showMessagesTab } = useShouldShowMessagingRoutes();
  const shouldShowAgentsTab = useShouldShowAgentsTab();
  const renderNotificationsCount = () => (
    <CounterBadge count={roundedCount(unreadChatRoomsCount)} />
  );

  const renderCommunityInboxCount = () => {
    if (!totalBotChatCount) {
      return <Icon type="16-stars-ai" size={16} />;
    }
    return <CounterBadge count={roundedCount(totalBotChatCount)} />;
  };

  return (
    <Tab.Group className="bg-primary overflow-hidden">
      <div className="border-primary border-b px-6">
        <Tab.List>
          {showMessagesTab && (
            <Tab className={className}>{t("messaging.inbox")}</Tab>
          )}
          {showMessagesTab && (
            <Tab className={className}>
              <div className="flex items-center gap-1.5">
                {t("messaging.unread")}
                {renderNotificationsCount()}
              </div>
            </Tab>
          )}
          {shouldShowAgentsTab && (
            <Tab>
              <div className="flex items-center gap-1.5 text-sm">
                {t("messaging.agents")}
                {renderCommunityInboxCount()}
              </div>
            </Tab>
          )}
        </Tab.List>
      </div>
      <Tab.Panels>
        {showMessagesTab && (
          <Tab.Panel>
            <DMsList />
          </Tab.Panel>
        )}
        {showMessagesTab && (
          <Tab.Panel>
            {unreadChatRoomsCount === 0 ? (
              <ListWrapper>
                <NoUnreadMessages />
              </ListWrapper>
            ) : (
              <DMsList unreadChatRoomUuids={unreadChatRoomUuids} />
            )}
          </Tab.Panel>
        )}
        {shouldShowAgentsTab && (
          <Tab.Panel>
            <ListWrapper>
              <BotChatList />
            </ListWrapper>
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );
};
