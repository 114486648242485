import { useRef } from "react";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import { t } from "@/i18n-js/instance";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { BadgeIndicator } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/BadgeIndicator";

export interface NotificationPopoverButtonProps {
  notificationCount: number;
  isOpen?: boolean;
}

export const NotificationPopoverButton = ({
  notificationCount,
  isOpen = false,
}: NotificationPopoverButtonProps) => {
  const notificationsButtonRef = useRef<HTMLButtonElement | null>(null);
  const { isV3Enabled } = useIsV3();

  useHotkeys(
    "alt+n",
    () => {
      notificationsButtonRef?.current?.click();
    },
    [notificationsButtonRef],
  );

  return (
    <div className="relative">
      <TippyV2 interactive={false} content={t("header_v3.notifications")}>
        <IconButton
          name={isV3Enabled ? "20-bell-v3" : "16-notifications"}
          buttonSize={isV3Enabled ? 36 : 28}
          iconSize={isV3Enabled ? 20 : 16}
          className={classNames(
            "hover:!bg-c-header-hover focus:!bg-c-header-hover !rounded-md p-1.5 duration-150",
            {
              "!text-c-header hover:!text-c-header-active focus:!text-c-header-active":
                isV3Enabled,
              "!bg-c-header-active !text-c-header-active":
                isV3Enabled && isOpen,
            },
          )}
          iconClassName={classNames({
            "!text-c-header": !isV3Enabled,
            "!text-inherit !transition-none": isV3Enabled,
          })}
          ariaLabel={t("header_v3.notifications")}
          dataTestId="notifications-menu-popover-button"
          ref={notificationsButtonRef}
        />
        {notificationCount > 0 && (
          <BadgeIndicator
            count={notificationCount}
            dataTestId="unread-notifications-count"
          />
        )}
      </TippyV2>
    </div>
  );
};
