import PropTypes from "prop-types";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { useSpotlightSearch } from "@/react/components/Modals/SpotlightSearch";
import { Icon } from "@circle-react-shared/Icon";
import { useHeaderBorderStyles } from "./useHeaderColors";

export const GlobalSearch = ({ isPreviewMode = false }) => {
  const spotlightSearch = useSpotlightSearch();
  const styles = useHeaderBorderStyles();

  const showSpotlightSearch = () => {
    spotlightSearch.show();
  };
  return (
    <div className="absolute inset-1/2 top-2 w-[480px] -translate-x-1/2">
      <div className="relative justify-self-center">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Icon type="16-search" size={16} className="text-c-header" />
        </div>
        <button
          type="submit"
          onClick={isPreviewMode ? noop : showSpotlightSearch}
          className="border-primary bg-c-header hover:bg-c-header-hover focus-visible:bg-c-header-hover text-c-header hover:border-primary focus:border-primary flex max-h-8 w-[30rem] items-center rounded-lg border py-2 pl-10 text-sm transition-colors duration-150 ease-linear focus:outline-none focus:ring-0"
          style={{ ...styles }}
        >
          {t("search_item")}
        </button>
      </div>
    </div>
  );
};

GlobalSearch.propTypes = {
  isPreviewMode: PropTypes.bool,
};
