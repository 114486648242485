import classnames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { useShouldShowMessagingRoutes } from "@circle-react/hooks/useIsMessagingEnabled";
import { PopoverPortal } from "@circle-react-uikit/PopoverPortal";
import { DEFAULT_POPPER_OPTIONS } from "../constants";
import { DMsPopoverButton } from "./DMsPopoverButton";
import { Header } from "./Header";
import { TabHeader } from "./TabHeader";

export const DirectMessages = () => {
  const { shouldShowMessagingRoutes } = useShouldShowMessagingRoutes();
  const { isViewOnlyMasquerading } = usePunditUserContext();

  if (!shouldShowMessagingRoutes) {
    return null;
  }

  return (
    <PopoverPortal
      shouldCloseOnLocationChange
      renderButton={isOpen => <DMsPopoverButton isOpen={isOpen} />}
      className={classnames(
        "border-primary bg-primary h-full max-h-[37rem] w-full max-w-[30rem] rounded-lg border shadow-lg",
        {
          "cursor-not-allowed": isViewOnlyMasquerading,
        },
      )}
      popperOptions={DEFAULT_POPPER_OPTIONS}
      shouldHideOnMdScreens={false}
    >
      <div
        className={classnames("h-full w-full overflow-hidden", {
          "pointer-events-none": isViewOnlyMasquerading,
        })}
        data-testid="direct-messages-drawer"
      >
        <Header />
        <TabHeader className="text-sm" />
      </div>
    </PopoverPortal>
  );
};
