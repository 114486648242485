import { t } from "@/i18n-js/instance";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import type { PostBookmarkRecord } from "@circle-react/types/Bookmarks";
import { MiddleDot } from "@circle-react-shared/uikit/MiddleDot";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";
import { GalleryPreview } from "./GalleryPreview";

export interface PostProps {
  bookmark: PostBookmarkRecord;
}

export const Post = ({ bookmark }: PostProps) => {
  const {
    created_at,
    title,
    author_image: authorImage,
    author_name: authorName,
    plain_text_body: plainTextBody,
    attachment_count: attachmentCount,
    gallery,
  } = bookmark.bookmark_record ?? {};

  const createdAtString = formattedDateTime({
    dateTime: created_at ? new Date(created_at) : new Date(),
    format: "long_date",
  });

  const hasAttachments = Boolean(attachmentCount && attachmentCount > 0);

  return (
    <div className="flex items-start gap-3">
      <UserImage src={authorImage ?? ""} name={authorName ?? ""} size="10" />
      <div className="flex flex-col gap-0.5">
        {title && (
          <Typography.LabelSm weight="semibold">
            <span className="line-clamp-2 leading-5">{title}</span>
          </Typography.LabelSm>
        )}

        {!!gallery && (
          <GalleryPreview gallery={gallery} attachmentCount={attachmentCount} />
        )}

        {plainTextBody && plainTextBody.trim() !== "" ? (
          <Typography.LabelSm color="text-default">
            <span className="line-clamp-2 leading-5">{plainTextBody}</span>
          </Typography.LabelSm>
        ) : (
          hasAttachments &&
          !gallery && (
            <Typography.LabelSm color="text-default">
              {t("bookmarks.attachments_count", {
                count: attachmentCount,
              })}
            </Typography.LabelSm>
          )
        )}

        <Typography.LabelXs color="text-default">
          {authorName} <MiddleDot className="text-sm font-semibold" />{" "}
          {createdAtString}
        </Typography.LabelXs>
      </div>
    </div>
  );
};
